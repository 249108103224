<template>
  <div>
    <div :style="`height:40vh; background:url(https://vea.global/wp-content/uploads/2020/08/Comunidad-Andina-digitaliza-tramites-para-facilitar-comercio-de-productos-cosmeticos.png); background-size:cover; margin-top:-100px`">
    </div> 
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="title">
          DETALLE DE PEDIDO - #{{id}}
        </div>
        <hr class="title-line">
      </div>      
    </div>             
      <hr>
     <div class="container mb-5">
        <div class="row">
            <table class="table table-striped">
                <thead class="bg-dark text-light">
                    <th>Codigo</th>
                    <th>Producto</th>
                    <th>Precio</th>
                    <th>Cantidad</th>
                    <th>Subtotal</th>
                </thead>
                <tbody>
                    <tr v-for="product in order.details" :key="product.id">
                        <td>{{product.code}}</td>
                        <td>{{product.name}}</td>
                        <td>Q {{(product.price).toFixed(2)}}</td>
                        <td>{{product.quantity}}</td>
                        <td>Q {{(product.price * product.quantity).toFixed(2)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <th colspan="4">Total</th>
                    <th v-if="order.total">Q{{order.total.toFixed(2)}}</th>
                </tfoot>
            </table>
        </div>
     </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      order: {},
      total: 0,
      table: "order",
      status:null,
      text:''
    };
  },

  async mounted() {
    const data = { table: this.table, id: this.id };
    this.order = await this.$store.dispatch("findById", data);
    this.status = this.order.status
    this.textChange(this.status)
  },
  methods: {
    textChange(status){
      this.text = status==1 ? 'Cambiar a: para Paquetería' : status==2 ? 'Cambiar a: Listo para envío/entrega' 
      : status==3 ? 'Cambiar a: Enviado ' : 'Cambiar a: Entregado'
    },
    async changeStatus() {
      let status = this.order.status + 1;
      const data = {
        table: this.table,
        id: this.id,
        data: { status: status },
      };
      let typeOfTask = status == 2 ? 'packager' : status == 3 ? 'checker' : status == 4 ? 'sender' : ''
      await this.$store.dispatch("update", data);
      if(status == 2 || status == 3 || status == 4){
        const req = await this.$store.dispatch("saveNew", {
          table: "order/assignTasks/",
          data: {orderId: this.id, typeOfTask: typeOfTask}
        });
      }

      setTimeout(() => {
        this.$router.push({ name: "orders" });
        this.textChange(this.status)
      }, 300);
    },
  },
};
</script>

<style>
</style>